@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

.language_box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 18px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-segoe {
  font-family: 'Segoe UI', Verdana, sans-serif;
}

.font-helvetica {
  font-family: 'Helvetica', sans-serif;
}

.font-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.error-box {
  @apply text-center p-3 rounded-lg border border-red-500 bg-red-100 mb-6 text-red-600;
}

.quill-content .ql-editor {
  font-size: 16px; /* or whatever size you want */
}

.labelContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.textBubble {
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 8px;
  font-size: 14px;
  bottom: 100%;
  left: 0;
  white-space: normal;
  max-width: 250px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  z-index: 999;
}

.labelContainer:hover .textBubble {
  opacity: 1;
}

@media (max-width: 768px) {
  .textBubble {
    font-size: 12px;
    padding: 6px;
  }
}

.shadow-on-hover-blue {
  transition: box-shadow 0.3s ease-in-out;
}

.shadow-on-hover-blue:hover {
  box-shadow:
    0 4px 14px rgba(96, 221, 255, 0.184),
    0 2px 4px rgba(96, 221, 255, 0.105);
}

.shadow-on-hover-red {
  transition: box-shadow 0.3s ease-in-out;
}

.shadow-on-hover-red:hover {
  box-shadow:
    0 4px 14px rgba(255, 96, 96, 0.184),
    0 2px 4px rgba(255, 81, 81, 0.105);
}

.shadow-on-hover {
  transition: box-shadow 0.3s ease-in-out;
}

.shadow-on-hover:hover {
  box-shadow:
    0 4px 14px rgba(0, 0, 0, 0.1),
    0 2px 4px rgba(0, 0, 0, 0.04);
}

.bend-on-hover {
  position: relative;
  overflow: hidden;
}

.bend-on-hover .glare {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 80%
  );
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.bend-on-hover:hover .glare {
  opacity: 1;
}

.rotate-container > * {
  pointer-events: none;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

form {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

body {
  background-color: #f4faff;
  font-family: Poppins, sans-serif;
  color: rgba(32, 59, 83, 0.77);
  font-size: 17px;
  line-height: 178%;
}

h1 {
  margin-top: 0px;
  margin-bottom: 28px;
  color: #161819;
  font-size: 64px;
  line-height: 113%;
  font-weight: 500;
  letter-spacing: -0.03em;
}

h2 {
  margin-top: 0px;
  margin-bottom: 28px;
  color: #161819;
  font-size: 54px;
  line-height: 114%;
  font-weight: 500;
  letter-spacing: -0.02em;
}

h3 {
  margin-top: 0px;
  margin-bottom: 24px;
  color: #161819;
  font-size: 42px;
  line-height: 116%;
  font-weight: 500;
  letter-spacing: -0.015em;
}

h4 {
  margin-top: 0px;
  margin-bottom: 22px;
  color: #161819;
  font-size: 36px;
  line-height: 118%;
  font-weight: 600;
  letter-spacing: -0.015em;
}

h5 {
  /* margin-top: 0px;
     margin-bottom: 20px; */
  margin-top: 20px;
  margin-bottom: 10px;
  color: #161819;
  font-size: 26px;
  line-height: 120%;
  font-weight: 500;
  letter-spacing: -0.015em;
}

h6 {
  margin-top: 0px;
  margin-bottom: 17px;
  color: #161819;
  font-size: 20px;
  line-height: 130%;
  font-weight: 500;
  letter-spacing: -0.015em;
}

p {
  margin-bottom: 28px;
}

a {
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #161819;
  text-decoration: underline;
}

ul {
  display: list-item;
  margin-top: 0px;
  margin-bottom: 28px;
  padding-left: 36px;
}

ol {
  margin-top: 0px;
  margin-bottom: 28px;
  padding-left: 36px;
}

li {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 8px;
  list-style-type: circle;
}

strong {
  color: #161819;
  font-weight: 700;
}

em {
  color: #161819;
  font-style: italic;
}

blockquote {
  margin-bottom: 28px;
  margin-left: 18px;
  padding: 0px 24px;
  border-left: 2px solid #2cc1ff;
  color: #161819;
  font-size: 19px;
  line-height: 178%;
}

figure {
  margin-top: 38px;
  margin-bottom: 38px;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
}

figcaption {
  margin-top: 0px;
  padding: 12px 15px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
  font-size: 14px;
  line-height: 176%;
  font-weight: 600;
  text-align: center;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  z-index: 999;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo {
  max-width: 270px;
  width: 100%;
  height: auto;
}

/* Media query for smaller viewports */
@media (max-width: 767px) {
  .logo-responsive {
    max-width: 150px;
    /* Adjust this value to the desired width on smaller viewports */
  }
}

/* Media query for even smaller viewports */
@media (max-width: 479px) {
  .logo-responsive {
    max-width: 100px;
    /* Adjust this value to the desired width on the smallest viewports */
  }
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -10;
  background-color: #f4faff;
  box-shadow: 0 20px 30px 0 rgba(55, 103, 126, 0.05);
}

.nav-link {
  padding: 11px 20px;
  border-radius: 50px;
  color: rgba(32, 59, 83, 0.77);
  font-size: 16px;
  line-height: 130%;
  font-weight: 400;
  text-align: center;
}

.nav-link:hover {
  color: #161819;
}

.nav-link.w--current {
  color: #161819;
}

.nav-button {
  margin-left: 15px;
  padding: 11px 22px;
  border-radius: 12px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease,
    background-color 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.nav-button-transparent {
  margin-left: 15px;
  padding: 11px 22px;
  border-radius: 12px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease,
    background-color 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  font-family: Poppins, sans-serif;
  color: black;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: black 1px solid;
}

@media (max-width: 767px) {
  .nav-button-transparent {
    max-width: 120px;
    padding: 8px 10px;
    font-size: 12px;
    word-break: break-all;
    overflow-wrap: break-word;
    display: inline-block;
    line-height: normal;
  }
}

.nav-button:hover {
  background-color: #2cc1ff;
  box-shadow: 0 10px 30px -10px #2cc1ff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

.section-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 120px 25px 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease,
    -webkit-transform 300ms ease;
}

.section-hero.single-page {
  padding-bottom: 0px;
}

.overflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

.content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.block-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.heading-hero {
  max-width: 1060px;
  font-size: 68px;
}

.paragraph-large {
  max-width: 600px;
  margin-bottom: 36px;
  font-size: 20px;
  line-height: 178%;
}

.grid-button {
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button {
  padding: 16px 32px;
  border-radius: 12px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.button:hover {
  background-color: #2cc1ff;
  box-shadow: 0 15px 50px -10px #2cc1ff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}
.button-unlock {
  padding: 16px 16px;
  border-radius: 12px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.button-unlock:hover {
  background-color: #2cc1ff;
  box-shadow: 0 15px 50px -10px #2cc1ff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

.transparent-button {
  background-color: transparent;
  padding: 16px 32px;
  border-radius: 12px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: black;
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid black;
}

.transparent-button:hover {
  color: black;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.transparent-button-unlock {
  background-color: transparent;
  padding: 16px 16px;
  border-radius: 12px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: black;
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid black;
}

.transparent-button-unlock:hover {
  color: black;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.button-suggestion {
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 12px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: black;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.147);
  box-shadow: 3px 3px 6px 0.05px #00bfff37;
}

.button-suggestion:hover {
  color: black;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, 1.5px);
  box-shadow: 0px 0px 0px 0px #00bfff64;
}

.button-regenerate {
  background-color: transparent;
  padding: 8px 16px;
  border-radius: 50px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: black;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid black;
}

.button-regenerate:hover {
  color: black;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -2px);
}

.transparent-button-small-rounded {
  background-color: transparent;
  padding: 12px 24px;
  border-radius: 50px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: black;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid black;
}

.transparent-button-small-rounded:hover {
  color: black;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.transparent-button-small {
  background-color: transparent;
  padding: 12px 20px;
  border-radius: 12px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: black;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid black;
}

.transparent-button-small:hover {
  color: black;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.transparent-button-small-example {
  background-color: transparent;
  padding: 12px 20px;
  border-radius: 12px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: black;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid black;
}

.transparent-button-small-example:hover {
  color: black;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.button.submit {
  display: block;
  margin-top: 12px;
  border-radius: 12px;
}

.button.white {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  align-self: flex-start;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(55, 103, 126, 0.05);
  -webkit-transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  color: #161819;
}

.button.white:hover {
  box-shadow: 0 15px 50px -10px rgba(55, 103, 126, 0.05);
}

.icon-lightbox {
  width: 24px;
  height: 24px;
}

.text-lightbox {
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
}

.lightbox-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 32px 15px 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(55, 103, 126, 0.05);
  -webkit-transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    transform 300ms ease,
    -webkit-transform 300ms ease;
  text-decoration: none;
}

.lightbox-link:hover {
  box-shadow: 0 15px 50px -10px rgba(55, 103, 126, 0.05);
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #161819;
}

.hero-img {
  width: 100%;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
}

.block-hero-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.blurred {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.blurred-blue---hero {
  z-index: -100;
  position: absolute;
  width: 120%;
  max-width: none;
  margin-top: -120%;
  margin-left: -90%;
}

.blurred-red---hero {
  z-index: -100;
  position: absolute;
  width: 120%;
  max-width: none;
  margin-top: -100%;
  margin-left: -60%;
}

/* Add media queries for mobile devices */
@media (max-width: 767px) {
  .blurred-blue---hero {
    margin-top: -170%; /* Adjust the value as needed */
    margin-left: -50%; /* Adjust the value as needed */
  }

  .blurred-red---hero {
    margin-top: -150%; /* Adjust the value as needed */
    margin-left: -30; /* Adjust the value as needed */
  }
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 100px 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  width: 100%;
}

.section.hide {
  display: none;
}

.heading {
  max-width: 1000px;
}

.block-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.grid-2-columns {
  width: 100%;
  margin-top: 10px;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-2-columns.single {
  margin-top: 0px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 60px 94px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
}

.block-heading-feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 28px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-feature {
  margin-bottom: 0px;
}

.icon-feature {
  width: 33px;
  height: 33px;
  margin-right: 18px;
}

.image-feature {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  color: #2cc1ff;
}

.grid-3-columns {
  width: 100%;
  margin-top: 10px;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.sequence {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 40px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
}

.icon-sequence-bg {
  position: relative;
  margin-bottom: 23px;
  padding: 20px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 30px -5px rgba(55, 103, 126, 0.05);
}

.icon-sequence {
  width: 40px;
  height: 40px;
}

.number-sequence {
  color: #fff;
  font-size: 14px;
  line-height: 100%;
  font-weight: 700;
}

.number-sequence-bg {
  position: absolute;
  left: auto;
  top: -7px;
  right: -7px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #161819;
}

.heading-sequence {
  max-width: 250px;
}

.block-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.grid-img {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
  -o-object-fit: cover;
  object-fit: cover;
}

.blurred-hero {
  position: relative;
  margin-top: 150px;
  color: #2cc1ff;
}

.blurred-grid {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blurred-blue---grid {
  position: absolute;
  width: 180%;
  max-width: none;
  margin-top: -61%;
  margin-left: -28%;
}

.blurred-red---grid {
  position: absolute;
  width: 200%;
  max-width: none;
  margin-top: -52%;
  margin-left: -84%;
}

.plan {
  position: relative;
  padding: 40px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
}

.price-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.price {
  color: #161819;
  font-size: 60px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: -0.03em;
}

.price-info {
  margin-left: 8px;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.015em;
}

.paragraph-plan {
  max-width: 280px;
  margin-bottom: 22px;
}

.check-block-plan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.check-plan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  width: 36px;
  height: 36px;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: rgba(44, 193, 255, 0.1);
}

.subItem {
  display: flex;
  align-items: flex-start;
  margin-left: 1.5rem;
  font-size: small;
}

.subItem .check-plan {
  width: 24px;
  height: 24px;
}

.text-check-plan {
  color: #161819;
  line-height: 140%;
  font-weight: 600;
}

.check-plan.accent-check {
  background-color: rgba(254, 108, 102, 0.1);
}

.icon-check {
  width: 14px;
  height: 14px;
}

.grid-check-plan {
  margin-bottom: 30px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-small {
  padding: 14px 22px;
  border-radius: 10px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.button-small:hover {
  background-color: #2cc1ff;
  box-shadow: 0 15px 50px -10px #2cc1ff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

.button-small-red {
  padding: 14px 22px;
  border-radius: 10px;
  background-color: #f14c4c;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.button-small-red:hover {
  background-color: #ff0000;
  box-shadow: 0 15px 50px -10px #ff0000;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

.button-small-red-transparent {
  background-color: transparent;
  padding: 10px 18px;
  border-radius: 12px;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: red;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border: 1px solid red;
}

.button-small-red-transparent:hover {
  background-color: #ff0000;
  box-shadow: 0 15px 50px -10px #ff0000;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

.button-small-rounded {
  padding: 12px 24px;
  border-radius: 50px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.button-small-rounded:hover {
  background-color: #2cc1ff;
  box-shadow: 0 15px 50px -10px #2cc1ff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

.button-small.white {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(55, 103, 126, 0.05);
  -webkit-transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    -webkit-transform 300ms ease;
  color: #161819;
}

.button-small.white:hover {
  box-shadow: 0 15px 50px -10px rgba(55, 103, 126, 0.05);
}

.accent-plan {
  position: absolute;
  left: auto;
  top: -18px;
  right: 25px;
  bottom: auto;
  display: inline-block;
  padding: 8px 18px;
  border-radius: 8px;
  background-color: #fe6c66;
  box-shadow: 0 15px 50px -10px #fe6c66;
  color: #fff;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
}

.block-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-right: 6%;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.testimonial {
  padding: 35px 40px;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
}

.client {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.client-avatar {
  width: 60px;
  height: 60px;
  margin-right: 18px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 15px 30px -5px rgba(55, 103, 126, 0.05);
  -o-object-fit: cover;
  object-fit: cover;
}

.client-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.name {
  margin-bottom: 3px;
}

.info {
  opacity: 0.6;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
}

.paragraph-testimonial {
  margin-bottom: 0px;
}

.grid-testimonial {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.collection-list-wrapper {
  width: 100%;
  margin-top: 10px;
}

.collection-list {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.block-blog {
  padding: 40px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
}

.heading-blog {
  margin-bottom: 0px;
}

.link-heading-blog {
  margin-bottom: 25px;
  text-decoration: none;
}

.date-blog {
  margin-bottom: 8px;
  opacity: 0.6;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
}

.paragraph-summary {
  margin-bottom: 32px;
}

.empty-text {
  color: #161819;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
}

.empty-state {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 12px;
  background-color: rgba(230, 241, 250, 0.5);
}

.banner {
  position: relative;
  width: 100%;
}

.blurred-red---banner {
  position: absolute;
  width: 120%;
  max-width: none;
  margin-top: -37%;
  margin-left: -56%;
}

.blurred-blue---banner {
  position: absolute;
  width: 120%;
  max-width: none;
  margin-top: -41%;
  margin-left: -28%;
}

.grid-banner {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
}

.text-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 70px 20px 70px 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.img-banner {
  width: 100%;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  background-image: url('https://uploads-ssl.webflow.com/63ee3f457119b470e81918b1/63ee3f457119b41832191907_Banner.svg');
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.paragraph-banner {
  max-width: 420px;
}

.form-block-banner {
  width: 100%;
  max-width: 400px;
}

.form-banner {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-field {
  height: 55px;
  margin-bottom: 10px;
  padding: 15px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(32, 59, 83, 0.18);
  border-radius: 12px;
  background-color: #fff;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  color: #161819;
  font-size: 17px;
  line-height: 130%;
}

.text-field:focus {
  border-color: #2cc1ff;
}

.text-field::-webkit-input-placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.text-field:-ms-input-placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.text-field::-ms-input-placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.text-field::placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.success-message {
  padding: 15px 25px;
  border-style: dashed;
  border-width: 2px;
  border-color: #161819;
  border-radius: 12px;
  background-color: transparent;
  text-align: left;
}

.success-text {
  color: #161819;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
}

.error-message {
  padding: 15px 25px;
  border-style: dashed;
  border-width: 2px;
  border-color: #fe6c66;
  border-radius: 12px;
  background-color: transparent;
}

.error-text {
  color: #fe6c66;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
}

.section-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  padding: 100px 25px 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-footer {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 3fr;
  grid-template-columns: 3fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.block-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.logo-footer {
  height: 40px;
  margin-bottom: 35px;
}

.paragraph-footer {
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 178%;
}

.icon-footer {
  width: 26px;
  height: 26px;
  max-width: none;
  margin-right: 6px;
}

.icon-link-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #161819;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
  text-decoration: none;
}

.icon-link-footer:hover {
  color: #2cc1ff;
}

.heading-footer {
  margin-bottom: 28px;
  font-size: 18px;
}

.link-footer {
  padding-top: 8px;
  padding-bottom: 8px;
  color: rgba(32, 59, 83, 0.57);
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-decoration: none;
}

.link-footer:hover {
  color: #161819;
}

.footer-down {
  width: 100%;
  margin-top: 100px;
}

.text-footer-down {
  color: rgba(32, 59, 83, 0.5);
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
}

.link-footer-down {
  color: rgba(32, 59, 83, 0.77);
  text-decoration: none;
}

.link-footer-down:hover {
  color: #161819;
}

.block-single-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.block-single-page.center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.heading-single-page {
  max-width: 1020px;
}

.grid-faq {
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.accordion-item {
  border-radius: 12px;
  background-color: #fff;
}

.accordion-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 22px 35px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.accordion-content {
  display: block;
  overflow: hidden;
  padding-right: 35px;
  padding-left: 35px;
}

.paragraph-accordion {
  margin-bottom: 25px;
}

.heading-accordion {
  margin-bottom: 0px;
  font-size: 17px;
  line-height: 130%;
}

.icon-arrow {
  width: 14px;
  height: 14px;
  margin-left: 8px;
}

.collection-list-wrapper-large {
  width: 100%;
}

.collection-list-large {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.collection-item-large {
  border-radius: 24px;
}

.link-image-blog {
  width: 100%;
  -webkit-transition-property: none;
  transition-property: none;
}

.image-blog {
  width: 100%;
  max-height: 400px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
  -webkit-transition: background-color 300ms ease;
  transition: background-color 300ms ease;
  -o-object-fit: cover;
  object-fit: cover;
}

.image-blog:hover {
  background-color: rgba(196, 214, 230, 0.5);
}

.block-blog-large {
  padding: 50px 60px 30px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
}

.image-blog-main {
  width: 100%;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
  -o-object-fit: cover;
  object-fit: cover;
}

.date-blog-large {
  margin-bottom: 5px;
  color: rgba(32, 59, 83, 0.5);
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
}

.page-content {
  width: 100%;
  max-width: 740px;
}

.rich-text-block {
  width: 100%;
  max-width: 740px;
  margin-top: 60px;
  margin-bottom: 40px;
}

.blurred-blog {
  position: relative;
  margin-top: 60px;
  margin-bottom: 30px;
}

.blurred-red---blog {
  position: absolute;
  width: 50%;
  max-width: none;
  margin-top: 0%;
  margin-left: -100%;
}

.blurred-blue---blog {
  position: absolute;
  width: 50%;
  max-width: none;
  margin-top: -15%;
  margin-left: -40%;
}

.grid-image-page {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 70px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-page {
  width: 100%;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
  -o-object-fit: cover;
  object-fit: cover;
}

.image-company {
  width: 100%;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
  -o-object-fit: cover;
  object-fit: cover;
}

.grid-company-info {
  margin-top: 30px;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.company-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 40px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
}

.number-company-info {
  color: #161819;
  font-size: 58px;
  line-height: 100%;
  font-weight: 800;
  letter-spacing: -0.03em;
}

.number-company-info-bg {
  position: relative;
  margin-bottom: 24px;
  padding: 30px 34px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 30px -5px rgba(55, 103, 126, 0.05);
}

.accent-info {
  position: absolute;
  left: auto;
  top: -18px;
  right: -14px;
  bottom: auto;
  display: inline-block;
  padding: 8px 18px;
  border-radius: 8px;
  background-color: #2cc1ff;
  box-shadow: 0 15px 50px -10px #2cc1ff;
  color: #fff;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
}

.blurred-page {
  position: relative;
  width: 100%;
  margin-bottom: 70px;
}

.blurred-red---page {
  position: absolute;
  width: 120%;
  max-width: none;
  margin-top: -46%;
  margin-left: 28%;
}

.blurred-blue---page {
  position: absolute;
  width: 120%;
  max-width: none;
  margin-top: -33%;
  margin-left: 2%;
}

.grid-contact-information {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.contact-information {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 35px 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
}

.icon-contact-bg {
  position: relative;
  margin-right: 20px;
  padding: 26px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 30px -5px rgba(55, 103, 126, 0.05);
}

.icon-contact {
  width: 32px;
  height: 32px;
  max-width: none;
}

.info-contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.text-info-contact {
  margin-bottom: 5px;
  opacity: 0.6;
  font-size: 14px;
  line-height: 130%;
  font-weight: 600;
}

.heading-info-contact {
  margin-bottom: 0px;
}

.form-bg {
  padding: 50px 60px 60px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 50px -5px rgba(55, 103, 126, 0.05);
}

.heading-form {
  font-family: Poppins, sans-serif;
  font-weight: 800;
}

.form-block {
  margin-bottom: 0px;
}

.form {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.textarea {
  max-height: 350px;
  max-width: 650px;
  min-height: 180px;
  margin-bottom: 0px;
  padding: 15px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(32, 59, 83, 0.18);
  border-radius: 27px;
  background-color: #fff;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  color: #161819;
  font-size: 17px;
}

.textarea:focus {
  border-color: #2cc1ff;
}

.textarea::-webkit-input-placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.textarea:-ms-input-placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.textarea::-ms-input-placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.textarea::placeholder {
  color: rgba(32, 59, 83, 0.5);
}

.section-full {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding: 120px 25px 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.get-started {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1000px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.get-started2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 700px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.paragraph-form {
  max-width: 360px;
}

.form-block-started {
  margin-bottom: 0px;
}

.form-started {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding: 120px 25px 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 430px;
  margin-bottom: 0px;
  padding: 50px 60px 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: rgba(230, 241, 250, 0.5);
  text-align: center;
}

.utility-page-form {
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.utility-blurred {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -100;
}

.space {
  width: 100%;
  height: 1px;
  margin-top: 70px;
  margin-bottom: 70px;
  background-color: rgba(32, 59, 83, 0.18);
}

.grid-colors {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.block-color {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 35px 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(32, 59, 83, 0.18);
  border-radius: 12px;
  background-color: #f4faff;
}

.block-color.transparent-bg {
  background-color: rgba(230, 241, 250, 0.5);
}

.block-color.border {
  background-color: rgba(32, 59, 83, 0.18);
}

.block-color.white {
  background-color: #fff;
}

.block-color.shadow {
  background-color: rgba(55, 103, 126, 0.05);
}

.block-color.heading {
  background-color: #161819;
}

.block-color.paragraphs {
  background-color: rgba(32, 59, 83, 0.77);
}

.block-color.sky-blue {
  background-color: #2cc1ff;
}

.block-color.salmon {
  background-color: #fe6c66;
}

.text-color {
  color: #161819;
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
}

.text-color.white {
  color: #fff;
}

.buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(230, 241, 250, 0.5);
}

.grid-image-licensing {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-licensing {
  width: 100%;
  height: 250px;
  border-radius: 12px;
  background-color: rgba(230, 241, 250, 0.5);
  -o-object-fit: cover;
  object-fit: cover;
}

.block-icon-licensing {
  display: inline-block;
}

.icon-licensing-bg {
  position: relative;
  display: inline-block;
  margin-right: 18px;
  margin-bottom: 18px;
  padding: 26px;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 15px 30px -5px rgba(55, 103, 126, 0.05);
}

.icon-licensing {
  width: 32px;
  height: 32px;
  max-width: none;
}

.font {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(230, 241, 250, 0.5);
}

.manrope {
  margin-bottom: 0px;
  margin-left: 20px;
}

.body {
  font-family: Poppins, sans-serif;
  font-weight: 400;
}

.div-block {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: #fff;
}

.image {
  max-width: 120%;
}

.body-2 {
  font-family: Poppins, sans-serif;
}

.field-label {
  margin-bottom: 0px;
  font-weight: 400;
}

.field-label-2 {
  margin-bottom: 0px;
  font-weight: 400;
}

.button-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  /* -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: flex-start;
    align-self: flex-start; */
  border-radius: 12px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 130%;
  font-weight: 400;
  text-align: center;
}

.button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  align-self: flex-start;
  border-radius: 12px;
  background-color: #161819;
  -webkit-transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease;
  transition:
    transform 300ms ease,
    box-shadow 300ms ease,
    background-color 300ms ease,
    -webkit-transform 300ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  text-align: center;
}

.button-2:hover {
  background-color: #2cc1ff;
  box-shadow: 0 15px 50px -10px #2cc1ff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #fff;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 58px;
  }

  h2 {
    font-size: 46px;
  }

  h3 {
    font-size: 37px;
  }

  h4 {
    font-size: 29px;
  }

  h5 {
    font-size: 24px;
  }

  .nav-link {
    text-align: left;
  }

  .menu-button {
    margin-left: 6px;
    padding: 10px;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(55, 103, 126, 0.05);
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    color: #161819;
  }

  .menu-button.w--open {
    background-color: #fff;
    color: #2cc1ff;
  }

  .menu-icon {
    font-size: 22px;
    line-height: 100%;
  }

  .nav-menu {
    padding-right: 10px;
    padding-bottom: 25px;
    padding-left: 10px;
    background-color: #f4faff;
    box-shadow: 0 20px 30px 0 rgba(55, 103, 126, 0.05);
  }

  .section-hero {
    padding-top: 150px;
    padding-bottom: 80px;
  }

  .heading-hero {
    font-size: 54px;
  }

  .paragraph-large {
    font-size: 19px;
  }

  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .grid-2-columns {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .feature {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .image-feature {
    margin-top: 10px;
  }

  .grid-3-columns {
    display: -ms-grid;
    display: grid;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .blurred-hero {
    margin-top: 130px;
  }

  .price {
    font-size: 52px;
  }

  .price-info {
    font-size: 22px;
  }

  .grid-testimonial {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .collection-list {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .text-banner {
    padding: 50px 10px 50px 50px;
  }

  .section-footer {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .footer-down {
    margin-top: 80px;
  }

  .collection-list-large {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .block-blog-large {
    padding: 40px 40px 15px;
  }

  .rich-text-block {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .blurred-blog {
    margin-top: 30px;
  }

  .grid-image-page {
    margin-top: 30px;
    margin-bottom: 60px;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .grid-company-info {
    margin-top: 15px;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .number-company-info {
    font-size: 48px;
  }

  .blurred-page {
    margin-bottom: 60px;
  }

  .grid-contact-information {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .contact-information {
    padding: 25px;
  }

  .form-bg {
    padding: 40px;
  }

  .grid-image-licensing {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 19px;
  }

  .bpOpen {
    height: 100dvh !important;
  }

  .navbar {
    padding-right: 15px;
    padding-left: 15px;
  }

  .section-hero {
    padding-right: 15px;
    padding-bottom: 60px;
    padding-left: 15px;
  }

  .block-hero {
    padding-right: 20px;
    padding-left: 20px;
  }

  .heading-hero {
    font-size: 46px;
  }

  .paragraph-large {
    font-size: 18px;
  }

  .section {
    width: auto;
    padding: 60px 15px;
  }

  .block-heading {
    padding-right: 20px;
    padding-left: 20px;
  }

  .grid-2-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-3-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .block-right {
    padding: 20px;
  }

  .blurred-hero {
    margin-top: 100px;
  }

  .price-info {
    font-size: 20px;
  }

  .accent-plan {
    top: -5px;
  }

  .block-left {
    padding: 20px;
  }

  .collection-list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-banner {
    -ms-grid-columns: 3fr 1fr;
    grid-template-columns: 3fr 1fr;
  }

  .img-banner {
    min-height: 340px;
  }

  .section-footer {
    padding: 60px 35px 40px;
  }

  .grid-footer {
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .footer-down {
    margin-top: 60px;
  }

  .block-single-page {
    padding-right: 20px;
    padding-left: 20px;
  }

  .collection-list-large {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .rich-text-block {
    margin-top: 20px;
  }

  .blurred-blog {
    margin-top: 20px;
  }

  .grid-image-page {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-company-info {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .number-company-info {
    font-size: 43px;
  }

  .section-full {
    padding: 100px 15px 60px;
  }

  .utility-page-wrap {
    padding: 100px 15px 60px;
  }

  .grid-colors {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .div-block {
    width: 378px;
    height: 534px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 33px;
  }

  h3 {
    font-size: 27px;
  }

  h4 {
    font-size: 23px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 17px;
  }

  .logo {
    height: 30px;
  }

  /* .nav-button {
       display: none;
     }
    */
  .section-hero {
    padding-top: 125px;
    padding-bottom: 40px;
  }

  .heading-hero {
    font-size: 40px;
  }

  .grid-button {
    grid-auto-flow: row;
  }

  .section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .video-container {
    width: 100%;
  }
  .feature {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .sequence {
    padding: 30px 30px 10px;
  }

  .blurred-hero {
    margin-top: 70px;
  }

  .plan {
    padding: 30px;
  }

  .price {
    font-size: 42px;
  }

  .price-info {
    margin-left: 5px;
    font-size: 18px;
  }

  .accent-plan {
    right: -5px;
  }

  .testimonial {
    padding: 30px;
  }

  .block-blog {
    padding: 30px;
  }

  .grid-banner {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .text-banner {
    padding: 30px 30px 0px;
  }

  .img-banner {
    min-height: 380px;
    background-position: 0% 0%;
  }

  .form-banner {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-footer {
    padding-top: 40px;
  }

  .grid-footer {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .accordion-header {
    padding-right: 20px;
    padding-left: 20px;
  }

  .accordion-content {
    padding-right: 20px;
    padding-left: 20px;
  }

  .block-blog-large {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .rich-text-block {
    margin-top: 15px;
  }

  .blurred-blog {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .grid-image-page {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .company-info {
    padding: 30px 30px 10px;
  }

  .blurred-page {
    margin-bottom: 40px;
  }

  .contact-information {
    padding: 20px;
  }

  .form-bg {
    padding: 20px;
  }

  .section-full {
    padding-bottom: 40px;
  }

  .form-started {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .utility-page-wrap {
    padding-bottom: 40px;
  }

  .utility-page-content {
    padding: 40px 20px;
  }

  .grid-colors {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-image-licensing {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .icon-licensing-bg {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 24px;
  }

  .icon-licensing {
    width: 25px;
    height: 25px;
  }

  .font {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .manrope {
    margin-top: 20px;
    margin-left: 0px;
  }

  .div-block {
    width: 252px;
    height: 356px;
  }
}

#w-node-_6929d1c1-3cef-5adc-ae86-dd443211f8d1-cf1918c1 {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#w-node-_65a0b419-7cfb-461a-75bb-797db0fd0a86-a11918c9 {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
}

#w-node-f7100c35-b060-a705-17d9-cfab48292e96-a11918c9 {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
}

#w-node-_7a0d5aea-efa4-c170-510c-e0e1ddc1a454-411918cc {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#Email.w-node-_70a3f063-3de6-41c9-8f7e-3826d5b42e27-411918cc {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#Phone-number.w-node-_56ab00ca-70af-a2e6-4a59-ad329a2bd3dc-411918cc {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

@media screen and (max-width: 767px) {
  #w-node-_2b89f38d-417d-b761-09ed-84893bf9cd2d-3bf9cd2a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_2b89f38d-417d-b761-09ed-84893bf9cd2d-3bf9cd2a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #Email.w-node-_70a3f063-3de6-41c9-8f7e-3826d5b42e27-411918cc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #Phone-number.w-node-_56ab00ca-70af-a2e6-4a59-ad329a2bd3dc-411918cc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

.back-button {
  border-style: solid;
  border-color: #000;
  border-width: 1px;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tDMPKzSQ.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tMMPKzSQ.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tCMPI.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVFteOcEg.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVGdeOcEg.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVF9eO.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucXtAKPY.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLufntAKPY.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucHtA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVFteOcEg.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVGdeOcEg.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVF9eO.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VFteOcEg.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VGdeOcEg.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VF9eO.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VFteOcEg.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VGdeOcEg.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VF9eO.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VFteOcEg.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VGdeOcEg.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VF9eO.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVFteOcEg.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVGdeOcEg.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVF9eO.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucXtAKPY.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTufntAKPY.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucHtA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z11lFc-K.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1JlFc-K.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecmNE.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z11lFc-K.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z11lFc-K.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1JlFc-K.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEz0dL_nz.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzQdL_nz.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzwdL_nz.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzMdL_nz.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEz8dL_nz.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEz4dL_nz.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzAdLw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc3CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc-CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc2CsTKlA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc5CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc1CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc0CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjASc6CsQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xFIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xMIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xEIzIFKw.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xLIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xHIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xGIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu51xIIzI.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc3CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc-CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc2CsTKlA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc5CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc1CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc0CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ACc6CsQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic3CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic-CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic2CsTKlA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic5CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic1CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic0CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBic6CsQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc3CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc-CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc2CsTKlA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc5CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc1CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc0CsTKlA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBCc6CsQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxFIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxMIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxEIzIFKw.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxLIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxHIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxGIzIFKw.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxIIzI.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKOzY.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKOzY.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKOzY.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKOzY.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfABc4EsA.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCBc4EsA.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCxc4EsA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.quill > * {
  font-family: 'Poppins';
  border-color: inherit !important;
  color: inherit !important;
}
.quill > .ql-toolbar {
  /* border radius of the toolbar */
  border-radius: 10px 10px 0 0;
}
.quill > .ql-container {
  /* border radius of the container and for font size*/
  font-size: inherit;
  border-radius: 0 0 10px 10px;
}
.ql-toolbar.ql-snow .ql-picker-label {
  color: inherit !important;
  opacity: 0.76;
}
.ql-snow .ql-picker {
  color: inherit !important;
}

.ql-snow .ql-editor h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
.ql-snow .ql-editor h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.ql-snow .ql-editor p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

.ql-snow .ql-editor h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
.ql-snow .ql-editor h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}
.ql-snow .ql-editor h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.ql-snow .ql-editor h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
